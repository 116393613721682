/**
 * react ujs by default loads all components in the page - and assumes a single react webpack context.
 * We want to have separate webpack contexts for separate components, so that we would not load all components (an entire app)
 * when we need just a small subset.
 *
 * This creates a problem when a page's composition requires more than 1 pack. What happens (without the current workaround)
 * is that react_ujs runs twice, and at least on the first run it doesn't find the components that are still not loaded.
 *
 * To bypass this issue, we did the following:
 * 1. created the window.ujscontexts object, which contains pack names and contexts. Each created pack must place the name and context
 *    data in the object. see packs in app/assets/javascripts/react-webpacker/packs. Contrary to instructions online, do
 *    not load ReactRailsUjs in each individual pack.. it's done here instead.
 * 2. created this file, which finds all react-rails nodes, looks in the webpack contexts, and loads them one by one
 *    based on component name (choosing the right context for every component). Once all components are loaded, we perform
 *    'teardown' of native event created by react_ujs, so that the DOMContentLoaded event will not re-trigger the loading
 *    and print many errors to the console..
 * 3. Add a javascript_pack_tag at the end of every layout the loads react components.
 */
if ( window.ujscontexts ) {
    const CLASS_NAME_ATTR = 'data-react-class'
    // find all dom nodes and iterate on them, finding the right context to use for each node.
    let nodes = ReactRailsUJS.findDOMNodes()

    // Initialize mountList for all defined contexts.
    for ( let elem in window.ujscontexts.names) {
        let name = window.ujscontexts.names[elem]
        window.ujscontexts.contexts[name].mountList = []
    }

    for (let index = 0 ; index < nodes.length ; index++ ) {
        var className = nodes[index].getAttribute(CLASS_NAME_ATTR)
        for ( let name of window.ujscontexts.names) {
            let ctxKeys = window.ujscontexts.contexts[name].keys().map((x) => x.replace("./",""))
            if ( ctxKeys.includes(className) ) {
                window.ujscontexts.contexts[name].mountList.push(nodes[index])
            }
        }
    }

    // iterate contexts and for each mount relevant components - if any.
    for ( let elem in window.ujscontexts.names) {
        try {
            let name = window.ujscontexts.names[elem]
            let ReactRailsUJS = window.ujscontexts.ujs[name]
            // need the native for teardown.. if react-ujs version changes - this may break...
            let native = require('react_ujs/react_ujs/src/events/native')
            // tear down native events, since they probably have already using the default mount callback.
            native.teardown(ReactRailsUJS)

            if ( window.ujscontexts.contexts[name].mountList.length ) {
                ReactRailsUJS.useContext(window.ujscontexts.contexts[name])
                window.ujscontexts.contexts[name].mountList.forEach((e) => {
                    ReactRailsUJS.mountComponents(e.parentElement)
                })
            }

        } catch(e) {
            console.error('Failure when processing react components',e)
        }
    }
}